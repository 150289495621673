// @ts-nocheck TODO: fix typescript errors
import { memo, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import Footer from '@/components/Footer/Footer';
import Nav from '@/components/Nav/Nav';

import routes from '@/data/routes';

import { fetchMenusEndpoint } from '@/graphql/fetcher';

export type Props = PropsWithChildren<{}>;

function Layout({ children, menusData }: Props) {
  const router = useRouter();

  const { data, error } = useSWR(fetchMenusEndpoint, {
    fallbackData: menusData
  });

  if (error) {
    // TODO: handle error
  }

  const NavbarProps = useMemo(() => {
    return {
      links: data?.menuItems?.nodes,
      externalLinks: data?.acfOptionsFooter?.footerSettings?.externalLinks
    };
  }, [data]);

  const FooterProps = useMemo(() => {
    return {
      acfOptions: data?.acfOptionsFooter,
      settings: data?.allSettings
    };
  }, [data]);

  const [hasTransparentNav, setTransparentNav] = useState(false);

  const handleRouteChange = useCallback(
    (url: string) => {
      if (router.asPath !== url) {
        // TODO: handle route change
      }
    },
    [router.asPath]
  );

  useEffect(() => {
    // TODO add another if for single blog url
    setTransparentNav(router.asPath === routes.Home.path);

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, handleRouteChange, router.asPath]);

  return (
    <>
      <Nav {...NavbarProps} isTransparent={hasTransparentNav} />
      {children}
      <Footer {...FooterProps} />
      <div id="portal"></div>
    </>
  );
}

export default memo(Layout);
