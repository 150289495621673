import xss, { IFilterXSSOptions } from 'xss';

/**
 * DOM Sanitizer to protect against untrust inputs and XSS attacks
 *
 * @param {string} [dirtyInput=''] - Input to sanitize
 */
function sanitizer(dirtyInput: string, options?: IFilterXSSOptions): string {
  options = {
    whiteList: {
      a: ['class', 'target', 'href', 'title', 'data-internal-link'],
      abbr: ['class', 'title'],
      address: ['class'],
      area: ['class', 'shape', 'coords', 'href', 'alt'],
      article: ['class'],
      aside: ['class'],
      audio: ['class', 'autoplay', 'controls', 'crossorigin', 'loop', 'muted', 'preload', 'src'],
      b: ['class'],
      bdi: ['class', 'dir'],
      bdo: ['class', 'dir'],
      big: ['class'],
      blockquote: ['class', 'cite'],
      br: ['class'],
      caption: ['class'],
      center: ['class'],
      cite: ['class'],
      code: ['class'],
      col: ['class', 'align', 'valign', 'span', 'width'],
      colgroup: ['class', 'align', 'valign', 'span', 'width'],
      dd: ['class'],
      del: ['class', 'datetime'],
      details: ['class', 'open'],
      div: ['class', 'class'],
      dl: ['class'],
      dt: ['class'],
      em: ['class'],
      figcaption: ['class'],
      figure: ['class'],
      font: ['class', 'color', 'size', 'face'],
      footer: ['class'],
      h1: ['class'],
      h2: ['class'],
      h3: ['class'],
      h4: ['class'],
      h5: ['class'],
      h6: ['class'],
      header: ['class'],
      hr: ['class'],
      i: ['class'],
      iframe: [
        'class',
        'src',
        'height',
        'width',
        'frameborder',
        'allowfullscreen',
        'allow',
        'loading'
      ],
      img: ['src', 'alt', 'title', 'width', 'height', 'class'],
      ins: ['datetime', 'class'],
      li: ['class'],
      mark: ['class'],
      nav: ['class'],
      ol: ['class'],
      p: ['class'],
      pre: ['class'],
      s: ['class'],
      section: ['class'],
      small: ['class'],
      span: ['class'],
      sub: ['class'],
      summary: ['class'],
      sup: ['class'],
      strong: ['class'],
      strike: ['class'],
      table: ['width', 'border', 'align', 'valign', 'class'],
      tbody: ['align', 'valign', 'class'],
      td: ['width', 'rowspan', 'colspan', 'align', 'valign', 'class'],
      tfoot: ['align', 'valign', 'class'],
      th: ['width', 'rowspan', 'colspan', 'align', 'valign', 'class'],
      thead: ['align', 'valign', 'class'],
      tr: ['rowspan', 'align', 'valign', 'class'],
      tt: ['class'],
      u: ['class'],
      ul: ['class'],
      video: [
        'class',
        'autoplay',
        'controls',
        'crossorigin',
        'loop',
        'muted',
        'playsinline',
        'poster',
        'preload',
        'src',
        'height',
        'width'
      ]
    }
  };

  return xss(dirtyInput, options);
}

export default sanitizer;
