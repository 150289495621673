import {
  forwardRef,
  memo,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

import styles from './Newsletter.module.scss';

import { ArrowRightSVG, CrossSVG, SuccessSVG } from '@/assets/svgs';

export type Props = {
  className?: string;
  title?: string;
  light?: Boolean;
  animateScroll?: boolean;
};

const NEWSLETTER_ENDPOINT = `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/j3/v1/mailchimp/newsletter/`;
const NEWSLETTER_NONCE_KEY = 'b9eRuw4kPVp@hu9uUW(N';

enum NewsletterState {
  INITIAL,
  SUCCESS,
  ERROR
}

export type NewsletterHandle = {
  animateIn: () => void;
  animateOut: () => void;
};

const Newsletter = forwardRef<NewsletterHandle, Props>(
  ({ className, title, light = false, animateScroll = true }, ref) => {
    const [email, setEmail] = useState('');
    const newsletterRef = useRef<HTMLDivElement>(null);
    const validateRef = useRef<HTMLDivElement>(null);
    const [emailValid, setIsEmailValid] = useState(NewsletterState.INITIAL);
    const [validateText, setValidateText] = useState('');
    const newsletterTL = useRef() as MutableRefObject<GSAPTimeline>;

    const handleEmailChange = useCallback(
      (event: { target: { value: SetStateAction<string> } }) => {
        setEmail(event.target.value);
      },
      []
    );

    const handleEmailSubmit = useCallback(async () => {
      fetch(NEWSLETTER_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/plain',
          'j3-mailchimp-nonce': NEWSLETTER_NONCE_KEY
        },
        body: email
      })
        .then((response) => {
          if (response.ok) {
            setIsEmailValid(NewsletterState.SUCCESS);
            setValidateText('Subscribed successfully');
          }
          return response.json();
        })
        .then((responseBody) => {
          if (responseBody.data && responseBody.success === false) {
            const mailchimpResponse = JSON.parse(responseBody.data);
            if (mailchimpResponse.title === 'Member Exists') {
              setIsEmailValid(NewsletterState.ERROR);
              setValidateText('Already Subscribed');
            } else {
              setIsEmailValid(NewsletterState.ERROR);
              setValidateText('Invalid email');
            }
          }
        })
        .catch(() => {
          setIsEmailValid(NewsletterState.ERROR);
          setValidateText('Invalid email');
        });
    }, [email]);

    useEffect(() => {
      setIsEmailValid(NewsletterState.INITIAL);
    }, [email]);

    useEffect(() => {
      gsap.effects.fadeInFrom(validateRef.current, {});
    }, [emailValid]);

    useImperativeHandle(ref, () => ({
      animateIn: () => newsletterTL.current.play(),
      animateOut: () => newsletterTL.current.reverse()
    }));

    useEffect(() => {
      const q = gsap.utils.selector(newsletterRef);
      newsletterTL.current = gsap
        .timeline({
          paused: true
        })
        .fadeInFrom(q('.title'))
        .from(q('.fadeInFrom'), { autoAlpha: 0, duration: 0.568, ease: 'linear' }, '-=0.3')
        .expandLine(q('.underline'), '-=0.568');

      animateScroll &&
        ScrollTrigger.create({
          trigger: newsletterRef.current,
          start: 'top 80%',
          end: 'bottom top',
          once: true,
          onEnter: () => newsletterTL.current.play()
        });
    }, [animateScroll]);

    return (
      <div
        className={classnames(styles.Newsletter, className, light ? styles.light : '')}
        ref={newsletterRef}
      >
        {title && <div className={classnames(styles.title, 'title')}>{title}</div>}
        <form className={styles.form} onSubmit={(e) => e.preventDefault()}>
          <div
            className={classnames(styles.email, {
              [styles.isValid]: emailValid === NewsletterState.SUCCESS,
              [styles.isInvalid]: emailValid === NewsletterState.ERROR,
              [styles.hasInput]: email
            })}
          >
            <input
              type="email"
              placeholder="your@email.com"
              onChange={handleEmailChange}
              className={classnames('fadeInFrom')}
            />

            {emailValid === NewsletterState.SUCCESS ? (
              <>
                <span className={styles.validateText} ref={validateRef}>
                  {validateText}
                </span>
                <span className={classnames(styles.circle, styles.submit)}>
                  <SuccessSVG className={styles.svg} />
                </span>
              </>
            ) : null}

            {emailValid === NewsletterState.ERROR ? (
              <>
                <span className={styles.validateText} ref={validateRef}>
                  {validateText}
                </span>
                <span className={classnames(styles.circle, styles.submit)}>
                  <CrossSVG className={styles.svg} />
                </span>
              </>
            ) : null}

            {emailValid === NewsletterState.INITIAL ? (
              <button
                className={classnames(styles.circle, styles.submit, 'fadeInFrom')}
                onClick={handleEmailSubmit}
              >
                <ArrowRightSVG className={styles.svg} />
              </button>
            ) : null}

            <span className={classnames(styles.underline, 'underline')} />
          </div>
        </form>
      </div>
    );
  }
);

Newsletter.displayName = 'Newsletter';

export default memo(Newsletter);
