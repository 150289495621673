export const fetchMenusEndpoint = `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/graphql?queryId=get-menus`;
export const fetchTeamEndpoint = `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/graphql?queryId=get-team`;
export const fetchCompaniesEndpoint = `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/graphql?queryId=get-companies`;
export const fetchBanner = `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/graphql?queryId=get-user-banner`;

// @ts-ignore
export const fetcher = (url) =>
  fetch(url, {
    method: 'GET',
    mode: 'cors'
  })
    .then((r) => r.json())
    .then((data) => {
      return data.data;
    });
