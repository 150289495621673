export type Route = {
  readonly path: string;
  readonly title: string;
};

export interface Routes {
  readonly Home: Route;
  readonly Perspective: Route;
  readonly Team: Route;
  readonly Companies: Route;
  readonly Blog: Route;
  readonly DynamicBlog: Route;
  readonly Press: Route;
}

const routes: Routes = {
  Home: {
    path: '/',
    title: 'Home'
  },
  Perspective: {
    path: '/perspective/',
    title: 'Our Perspective'
  },
  Team: {
    path: '/team/',
    title: 'Our Team'
  },
  Companies: {
    path: '/companies/',
    title: 'Our Companies'
  },
  Blog: {
    path: '/blog/',
    title: 'Blog'
  },
  DynamicBlog: {
    path: '/blog/[postSlug]',
    title: 'Blog Article'
  },
  Press: {
    path: '/press/',
    title: 'Recent Press'
  }
};

export default routes;
