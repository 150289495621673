import { memo, MouseEvent } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import styles from './ThemedButton.module.scss';

import { ArrowDiagonalSVG, ArrowRightSVG } from '@/assets/svgs';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Action = 'action',
  Icon = 'icon'
}

export type Props = {
  className?: string;
  text: string;
  theme?: ButtonType;
  light?: Boolean;
  onClick?: (e: MouseEvent<HTMLSpanElement>) => void;
  url?: string;
  linkTarget?: React.HTMLAttributeAnchorTarget;
  rel?: React.HTMLAttributeReferrerPolicy;
  ariaLabel?: string;
  j3IsInternalURL?: boolean;
  active?: boolean;
};

function ThemedButton({
  className,
  text,
  theme = ButtonType.Primary,
  light = false,
  onClick,
  url = '',
  linkTarget = '_blank',
  rel = 'no-referrer',
  ariaLabel = '',
  j3IsInternalURL = false,
  active = false
}: Props) {
  const markUp = (
    <span
      className={classnames('ThemedButton', styles.ThemedButton, className, styles[theme], {
        [styles.light]: light,
        [styles.active]: active
      })}
      onClick={(e) => onClick && onClick(e)}
    >
      {text}
      {theme === 'primary' && (
        <span className={styles.circle}>
          <ArrowRightSVG className={styles.svg} />
        </span>
      )}
      {theme === 'secondary' &&
        (j3IsInternalURL ? (
          <ArrowRightSVG className={styles.svg} />
        ) : (
          <ArrowDiagonalSVG className={classnames(styles.svg, styles.external)} />
        ))}
      {theme === 'icon' && <ArrowDiagonalSVG className={styles.svg} />}
    </span>
  );

  if (j3IsInternalURL && url) {
    return (
      <Link href={url}>
        <a aria-label={ariaLabel}>{markUp}</a>
      </Link>
    );
  }

  return (
    <Link href={url}>
      <a aria-label={ariaLabel} target={linkTarget} rel={rel}>
        {markUp}
      </a>
    </Link>
  );
}

export default memo(ThemedButton);

