import gsap from 'gsap';
import CustomEase from 'gsap/dist/CustomEase';
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import SplitText from 'gsap/dist/SplitText';

const registerEffect: gsap.RegisterEffect = gsap.registerEffect;

function gsapInit() {
  // Plugins
  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CustomEase, SplitText);

  // Placeholder eases
  gsap.registerEase('defaultEase', gsap.parseEase('0.45, 0.0, 0.06, 1.0'));
  gsap.registerEase('ease1', gsap.parseEase('0.02, 0.05, 0.08, 0.93'));
  gsap.registerEase('ease2', gsap.parseEase('0.26, 0.00, 0.60, 0.30'));
  gsap.registerEase('ease3', gsap.parseEase('0.13, 0.27, 0.51, 1.00'));

  // Project eases
  gsap.registerEase('yPositionEase', gsap.parseEase('0.02, 0.05, 0.08,0.93'));
  gsap.registerEase('slotMachineInEase', gsap.parseEase('0.22, 0.58, 0.08, 0.94'));
  gsap.registerEase('slotMachineOutEase', gsap.parseEase('0.46, 0.00, 0.08, 0.94'));
  gsap.registerEase('expandLineEase', gsap.parseEase('0.05, 0.00, 0.00, 1.00'));

  // Project defaults
  gsap.defaults({ ease: 'defaultEase', duration: 0.4 });
  gsap.config({ nullTargetWarn: false });

  // Project effects
  registerEffect({
    name: 'setupFadeIn',
    extendTimeline: true,
    effect: (targets, config) => {
      return gsap.set(targets, { opacity: config?.opacity, y: config?.y });
    },
    defaults: {
      opacity: 0,
      y: 20
    }
  });

  registerEffect({
    name: 'fadeIn',
    extendTimeline: true,
    effect: (targets, config) => {
      return gsap.to(targets, {
        duration: config?.duration,
        opacity: config?.opacity,
        delay: config?.delay,
        stagger: config?.stagger,
        ease: config?.ease,
        clearProps: config?.clearProps
      });
    },
    defaults: {
      duration: 0.667,
      opacity: 1,
      delay: 0,
      stagger: 0,
      ease: 'linear',
      clearProps: ''
    }
  });

  registerEffect({
    name: 'translateIn',
    extendTimeline: true,
    effect: (targets, config) => {
      return gsap.to(targets, {
        duration: config?.duration,
        y: config?.y,
        delay: config?.delay,
        stagger: config?.stagger,
        ease: config?.ease,
        clearProps: config?.clearProps
      });
    },
    defaults: { duration: 0.667, y: 0, delay: 0, stagger: 0, ease: 'yPositionEase', clearProps: '' }
  });

  registerEffect({
    name: 'fadeInFrom',
    extendTimeline: true,
    effect: (targets, config) => {
      return gsap.from(targets, {
        duration: config?.duration,
        opacity: config?.opacity,
        y: config?.y,
        delay: config?.delay,
        stagger: config?.stagger,
        ease: config?.ease
      });
    },
    defaults: { duration: 0.568, y: 20, opacity: 0, delay: 0, stagger: 0, ease: 'ease1' }
  });

  registerEffect({
    name: 'slotMachineIn',
    extendTimeline: true,
    effect: (targets, config) => {
      return gsap.from(targets, {
        autoAlpha: 0,
        duration: config?.duration,
        yPercent: 100,
        immediateRender: true,
        delay: config?.delay,
        stagger: config?.stagger,
        ease: config?.ease
      });
    },
    defaults: { duration: 1, delay: 0, stagger: {}, ease: 'linear' }
  });

  registerEffect({
    name: 'slotMachineOut',
    extendTimeline: true,
    effect: (targets, config) => {
      return gsap.to(targets, {
        duration: config?.duration,
        autoAlpha: 0,
        yPercent: -100,
        delay: config?.delay,
        stagger: config?.stagger,
        ease: config?.ease
      });
    },
    defaults: { duration: 1, delay: 0, stagger: {}, ease: 'linear' }
  });

  registerEffect({
    name: 'expandLine',
    extendTimeline: true,
    effect: (targets, config) => {
      return gsap.fromTo(
        targets,
        {
          scaleX: 0
        },
        {
          duration: config?.duration,
          scaleX: 1,
          delay: config?.delay,
          stagger: config?.stagger,
          ease: config?.ease
        }
      );
    },
    defaults: {
      duration: 0.5,
      delay: 0,
      ease: 'expandLineEase',
      stagger: 0
    }
  });
}

export default gsapInit;
