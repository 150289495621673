import { memo, useCallback, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useLocalStorage } from '@mantine/hooks';
import classnames from 'classnames';
import useSWR from 'swr';

import styles from './Banner.module.scss';

import CrossSVG from '@/components/svgs/cross.svg';

import sanitizer from '@/utils/sanitizer';
import useLayout from '@/hooks/use-layout';

import { fetchBanner, fetcher } from '@/graphql/fetcher';

export type Props = {
  className?: string;
  title: string;
};

enum StatusType {
  active = 'active',
  dismissed = 'dismissed',
  initial = 'initial'
}

const cssCustomProperty = '--banner-height';
const lsTextKey = 'eclipse-banner-text';
const lsIdKey = 'eclipse-banner-id';

function Banner({ className, title }: Props) {
  const { mobile } = useLayout();

  const [storedValue, setStoredValue] = useLocalStorage({
    key: lsTextKey,
    defaultValue: StatusType.initial
  });
  const [storedAnnouncement, setStoredAnnouncement] = useState('');
  const [enable, setEnable] = useState(true);
  const [storedId, setStoredId] = useLocalStorage({ key: lsIdKey, defaultValue: '0' });

  const { data, error } = useSWR(fetchBanner, fetcher);

  useEffect(() => {
    const bannerData = data?.acfOptionsNotifications?.websiteNotificationBanner;

    if (bannerData) {
      const { bannerContent, bannerId, enabled } = bannerData;

      unstable_batchedUpdates(() => {
        setStoredAnnouncement(bannerContent);
        setStoredId(bannerId);
        setEnable(enabled);

        if (bannerId !== storedId && storedId !== '0') {
          setStoredValue(StatusType.initial);
        }
      });
    }
  }, [data, setStoredAnnouncement, setStoredId, setEnable, setStoredValue, storedId]);

  // useEffect(() => {
  //   // NOTE: To test local storage functionality
  //   setStoredId('');
  //   setStoredValue(StatusType.initial);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const handleClose = useCallback(() => {
    setStoredValue(StatusType.dismissed);
  }, [setStoredValue]);

  useEffect(() => {
    const bannerHeight = document.querySelector(`.${styles.Banner}`)?.clientHeight;
    const bannerHeightInRems = bannerHeight ? `${bannerHeight / 10}rem` : '0px';

    if (storedValue === StatusType.dismissed || !storedId) {
      // set custom property in css
      document.documentElement.style.setProperty(cssCustomProperty, '0px');
    } else {
      if (bannerHeight) {
        document.documentElement.style.setProperty(cssCustomProperty, bannerHeightInRems);
      }
    }

    return () => {
      document.documentElement.style.setProperty(cssCustomProperty, '0px');
    };
  }, [storedId, storedValue, mobile]);

  if (storedValue === StatusType.dismissed || !storedId || !enable || error || storedId === '0') {
    return null;
  }

  return (
    <div className={classnames(styles.Banner, className, 'banner')}>
      <div className={styles.textContainer}>
        <span className={styles.title}>{title}</span>
        <p
          className={styles.announcement}
          dangerouslySetInnerHTML={{ __html: sanitizer(storedAnnouncement) }}
        />
      </div>
      <div className={styles.btnContainer}>
        <button className={styles.close} onClick={handleClose}>
          <CrossSVG className={styles.svg} />
        </button>
      </div>
    </div>
  );
}

export default memo(Banner);
