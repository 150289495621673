import { useEffect, useRef } from 'react';
import { AppProps } from 'next/app';
import { FaustProvider } from '@faustjs/next';
import { SWRConfig } from 'swr';
import '../faust.config';
import '@/utils/why-did-you-render';
import 'swiper/scss';

import '@/styles/wordpress.scss';
import '@/styles/global.scss';

import Layout from '@/components/Layout/Layout';

import ResizeService from '@/services/resize';
import gsapInit from '@/utils/gsap-init';
import setBodyClasses from '@/utils/set-body-classes';

import { client } from '@/client';
import { fetcher } from '@/graphql/fetcher';

require('default-passive-events');
require('focus-visible');
gsapInit();

// This default export is required in a new `pages/_app.js` file.
// @ts-ignore
function App({ Component, pageProps, menusData }: AppProps) {
  // const { ...componentProps } = pageProps;
  const appMenusData = useRef(null);

  if (!appMenusData.current) {
    if (typeof window !== 'undefined') {
      // FE needs to parse _document data from head script
      appMenusData.current = JSON.parse(
        document?.getElementById('__MENUS_DATA__')?.textContent ?? ''
      );
    } else {
      // BE uses data passed from _document as a prop
      appMenusData.current = menusData;
    }
  }

  useEffect(() => {
    setBodyClasses();

    window.history.scrollRestoration = 'manual';

    if (process.env.NODE_ENV === 'development' && window.location.href.indexOf('?nostat') === -1) {
      import(/* webpackChunkName: "jam3-stats" */ '@jam3/stats').then((module) => module.default());
    }

    // Fix vh issues for mobile
    const calculateVh = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    };

    const calculateScrollbarWidth = () => {
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
      document.documentElement.style.setProperty(`--scrollbar-width`, `${scrollbarWidth}px`);
    };

    ResizeService.listen(calculateVh);
    ResizeService.listen(calculateScrollbarWidth);

    setTimeout(calculateVh, 50);
    setTimeout(calculateScrollbarWidth, 50);
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <FaustProvider client={client} pageProps={pageProps}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            revalidateIfStale: false,
            revalidateOnReconnect: false,
            revalidateOnMount: true,
            fetcher
          }}
        >
          {/* @ts-ignore */}
          <Layout menusData={appMenusData.current}>
            <Component {...pageProps} />
          </Layout>
        </SWRConfig>
      </FaustProvider>
    </>
  );
}

export default App;
