/**
 * return url string without trailing splash
 *
 * @export
 * @param {string} [path='']
 * @param {boolean} [cleanParams=false]
 * @returns {string}
 */
export function cleanUrl(path = '', cleanParams = false): string {
  if (!path) {
    return '';
  }
  if (cleanParams) {
    path = path?.split('?')[0];
  }
  if (path === '/') {
    return '/';
  }

  return path.replace(/\/$/, '').replace(/^\//, '') || '';
}

/**
 * check whether user browser supports webp format or not
 *
 * @export
 * @param {keyof typeof testImages} feature
 * @param {(isSupported: boolean) => void} callback
 */
const testImages = {
  lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
  lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
  alpha:
    'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
  animation:
    'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
};

export function checkWebpSupport(
  feature: keyof typeof testImages,
  callback: (isSupported: boolean) => void
): void {
  const img = new Image();
  img.onload = function () {
    const result = img.width > 0 && img.height > 0;
    callback(result);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = 'data:image/webp;base64,' + testImages[feature];
}

export function cleanupHTMLTags(html: string = '') {
  return html.replace(/<\/?[^>]+(>|$)/g, '');
}

interface MailtoOptions {
  email: string;
  subject: string;
  body: string;
}

export function getMailTo({ email, subject, body }: MailtoOptions) {
  return `mailto:${email}?subject=${subject}&body=${body}`;
}

export function htmlToMinutesToRead(text: string, speed = 275) {
  const wordCount = text
    .replace('@<(script|style)[^>]*?>.*?</\\1>@si', '')
    .replace(/(<([^>]+)>)/gi, '')
    .replace(/[\r\n\t ]+/, ' ')
    .match(/[\w\d’'-]+/gi)?.length;

  return wordCount ? Math.ceil(wordCount / speed) : 0;
}

export function formatDate(date: string) {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
}

export function isLinkActive(asPath: string, path: string) {
  if (!asPath || !path) {
    return false;
  }

  if (path.length > 1) {
    const checkSubStr = new RegExp(path);
    return checkSubStr.test(asPath);
  }

  return path === asPath;
}

export function hasScrollableContent(
  element: HTMLElement,
  direction: 'vertical' | 'horizontal' = 'vertical'
) {
  const scrollAmount = direction === 'vertical' ? element.scrollHeight : element.scrollWidth;
  const clientAmount = direction === 'vertical' ? element.clientHeight : element.clientWidth;

  const computed = window.getComputedStyle(element);

  const hasScrollableContent = scrollAmount > clientAmount;
  const overflowStyle = direction === 'vertical' ? computed.overflowY : computed.overflowX;
  const isOverflowHidden = overflowStyle === 'hidden';

  return hasScrollableContent && !isOverflowHidden;
}

export function calculatePageOffset(page: number, perPage: string) {
  if (page === 1) {
    return 0;
  }
  return parseInt(perPage) * (page - 1);
}

export const getRandomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const getSteppedRange = (start: number, stop: number, step = 1) =>
  Array(Math.ceil((stop - start + step) / step))
    .fill(start)
    .map((x, y) => x + y * step);

export const formatAudioTime = (time: number): string => {
  let minutes = Math.floor(time / 60);
  // let minutesStr = minutes >= 10 ? minutes : '0' + minutes;
  let seconds = Math.floor(time % 60);
  let secondsStr = seconds >= 10 ? seconds : '0' + seconds;
  return `${minutes}:${secondsStr}`;
};
