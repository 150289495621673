import { memo, MutableRefObject, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { gsap } from 'gsap';

import styles from './MobileNav.module.scss';

import LockBodyScrollService from '@/services/lock-body-scroll';
import { isLinkActive } from '@/utils/basic-functions';
import { duration6 } from '@/utils/eases';
import sass from '@/utils/sass';
import { NavbarProps } from '@/types';

import { BrandLogoSVG, DropdownSVG } from '@/assets/svgs';
import routes from '@/data/routes';

import ThemedButton, { ButtonType } from '../ThemedButton/ThemedButton';

function MobileNav({ className, isTransparent, links, externalLinks }: NavbarProps) {
  const refNav = useRef() as MutableRefObject<HTMLDivElement>;
  const [menuOpen, setMenuOpen] = useState(false);
  const hamburgerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const logoRef = useRef<HTMLAnchorElement>(null);
  const hamburgerTL = useRef() as MutableRefObject<GSAPTimeline>;
  const menuTL = useRef() as MutableRefObject<GSAPTimeline>;
  const { asPath } = useRouter() ?? {};

  const white = sass.color.white;

  function animatePageIn() {
    // TODO add banner here or in its own component
    gsap.from([hamburgerRef.current, logoRef.current], {
      autoAlpha: 0,
      duration: duration6,
      ease: 'linear'
    });
  }

  useEffect(() => {
    animatePageIn();
    const q = gsap.utils.selector(refNav);

    const children = hamburgerRef.current?.children;

    hamburgerTL.current = gsap
      .timeline({
        paused: true
      })
      .to([children[0], children[2]], {
        y: 0,
        duration: 0.23,
        ease: 'ease2'
      })
      .set(children[1], {
        opacity: 0
      })
      .to(children[0], {
        rotateZ: 45,
        duration: 0.23,
        ease: 'ease3'
      })
      .to(
        children[2],
        {
          rotateZ: -45,
          duration: 0.23,
          ease: 'ease3'
        },
        '-=0.23'
      );

    menuTL.current = gsap
      .timeline({
        paused: true,
        onReverseComplete: function () {
          gsap.set([hamburgerRef.current.children, logoRef.current!.children[0].firstChild], {
            clearProps: true
          });
        }
      })
      // overlay
      .set(refNav.current.children[0], { autoAlpha: 1 })
      .to(refNav.current.children[0], {
        // yPercent: 100,
        duration: 0.634,
        ease: 'ease1',
        // @ts-ignore
        y: (el) => gsap.getProperty(el, '--animated-height')
      })
      .to(
        hamburgerRef.current.children,
        {
          backgroundColor: white,
          duration: 0.634,
          ease: 'linear'
        },
        '-=0.634'
      )
      .to(
        logoRef.current!.children[0].firstChild,
        {
          fill: white,
          duration: 0.634,
          ease: 'linear'
        },
        '-=0.634'
      )
      // list items
      .fadeInFrom(q('.menuLink'), { stagger: 0.067 }, '-=0.45')
      .expandLine(q('.underline'), { stagger: 0.067 }, '-=0.468')
      .from(
        q('.extLink'),
        { autoAlpha: 0, duration: 0.568, stagger: 0.057, ease: 'linear' },
        '-=0.65'
      );
  }, [white]);

  useEffect(() => {
    hamburgerTL.current && hamburgerTL.current.progress(0).kill();
    menuTL.current && menuTL.current.progress(0).kill();
  }, [white]);

  useEffect(() => {
    if (menuOpen) {
      LockBodyScrollService.lock();
      menuTL.current.timeScale(1).play();
      hamburgerTL.current.play();
    } else {
      LockBodyScrollService.unlock();
      menuTL.current.timeScale(1.3).reverse();
      hamburgerTL.current.reverse();
    }
  }, [menuOpen]);

  return (
    <div
      className={classnames(styles.MobileNav, className, {
        [styles.open]: menuOpen,
        [styles.transparent]: isTransparent
      })}
    >
      <div className={styles.logoCon}>
        <Link href={routes.Home.path} className={styles.logo}>
          <a
            ref={logoRef}
            aria-label={`logo-link`}
            onClick={() => {
              setMenuOpen(false);
            }}
          >
            <BrandLogoSVG className={styles.logo} />
          </a>
        </Link>
      </div>
      <div
        className={styles.hamburger}
        ref={hamburgerRef}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <span className={styles.line} />
        <span className={styles.line} />
        <span className={styles.line} />
      </div>
      <div
        className={classnames(styles.wrapper, {
          [styles.open]: menuOpen
        })}
        ref={refNav}
      >
        <div className={styles.overlay} />
        <div className={styles.menu}>
          <ul className={styles.routes}>
            {links?.map((link) => (
              <li
                key={`${link?.label}-menu`}
                className={classnames({
                  [styles.hasChildren]: link?.childItems?.nodes?.length,
                  [styles.activeRoute]: isLinkActive(asPath, link?.path ?? '')
                })}
              >
                {!link?.parentId && link?.childItems?.nodes?.length ? (
                  <div className={styles.hasChildren}>
                    <div className={classnames('extLink', styles.link)}>{link.label}</div>
                    <div className={styles.childrenItems}>
                      {link?.childItems?.nodes?.map((link) => (
                        <Link href={link?.path ?? ''} key={`${link?.label}-menu`}>
                          <a
                            className={classnames({
                              [styles.activeRoute]: isLinkActive(asPath, link?.path ?? '')
                            })}
                            onClick={() => {
                              setMenuOpen(false);
                            }}
                          >
                            <div className="menuLink">{link?.label}</div>
                            <DropdownSVG className={classnames('extLink', styles.svg)} />
                            <span className={classnames(styles.underline, 'underline')} />
                          </a>
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : !link?.parentId ? (
                  <Link href={link?.path ?? ''}>
                    <a
                      className={classnames('menuLink')}
                      onClick={() => {
                        setMenuOpen(false);
                      }}
                    >
                      {link?.label}
                      <span className={styles.bottomLine} />
                    </a>
                  </Link>
                ) : null}
              </li>
            ))}
          </ul>
          <ul className={styles.externalLinks}>
            {externalLinks?.map((link) => (
              <li key={`${link?.label}-menu`}>
                <ThemedButton
                  theme={ButtonType.Icon}
                  url={link?.url ?? ''}
                  text={link?.label ?? ''}
                  onClick={() => {
                    setMenuOpen(false);
                  }}
                  j3IsInternalURL={false}
                  linkTarget="_blank"
                  className={classnames('extLink')}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default memo(MobileNav);
