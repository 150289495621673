import sass from '@/utils/sass';

export const ssrLayout = {
  mobile: false,
  tablet: false,
  tabletLandscape: false,
  desktop: true
};

function getLayout() {
  if (typeof window !== 'undefined') {
    const matchTablet = window.matchMedia(`(min-width: ${sass.layout.tablet})`);
    const matchTabletLandscape = window.matchMedia(`(min-width: ${sass.layout.tabletLandscape})`);
    const matchDesktop = window.matchMedia(`(min-width: ${sass.layout.desktop})`);

    const desktop = matchDesktop.matches;
    const tabletLandscape = matchTabletLandscape.matches && !desktop;
    const tablet = matchTablet.matches && !tabletLandscape && !desktop;
    const mobile = !tablet && !desktop;

    return {
      mobile,
      tablet,
      tabletLandscape,
      desktop
    };
  } else {
    return ssrLayout;
  }
}

export default getLayout;
